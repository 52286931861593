









































import { Component, Vue } from 'vue-property-decorator';
import { API_URL } from '@/config.ts';

@Component
export default class Revoke extends Vue {
  vaild = false;

  shortUrl = '';

  snakBarText = '';

  revokePwd = '';

  snackbar = false;

  snakbarTextColor = 'blue'

  urlRule = [
    (v: string) => (!!v && v.startsWith(API_URL)) || 'Please input the vaild Short URL!',
  ];

  pwdRule = [
    (v: string) => !!v || 'Please input the revoke password!',
  ];

  revoke() {
    if (this.vaild) {
      fetch(`${this.shortUrl}?revokePwd=${this.revokePwd}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': '; charset=utf-8',
        },
        credentials: 'same-origin',
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.code === 201) {
            this.snakBarText = 'URL revoked!';
            this.snakbarTextColor = 'blue';
            this.snackbar = true;
          } else if (data.code === 401) {
            this.snakBarText = 'Invaild password!';
            this.snakbarTextColor = 'error';
            this.snackbar = true;
            this.revokePwd = '';
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
    }
  }
}
