





import Vue from 'vue';
import Revoke from '@/components/Revoke.vue';

export default Vue.extend({
  components: {
    Revoke,
  },
});
